import React, { Suspense, lazy } from "react"
import Amplify, { API } from "aws-amplify"
import Auth from "@aws-amplify/auth"
import { Router, Route, Switch } from "react-router-dom"
import { createBrowserHistory } from "history"
import {
  AmplifyAuthenticator,
  AmplifySignUp,
  AmplifySignIn,
  AmplifySignOut,
  AmplifyForgotPassword
} from "@aws-amplify/ui-react"
import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import Alert from "@mui/material/Alert"
import { useMediaQuery } from "react-responsive"

// Handling AWS errors in a more user friendly way - custom toast
import Toast from "./components/Toast"
import { toast } from "react-toastify"
import { Hub, HubCallback } from "@aws-amplify/core"
import { AuthState, onAuthUIStateChange, UI_AUTH_CHANNEL, TOAST_AUTH_ERROR_EVENT } from "@aws-amplify/ui-components"

import "./css/main.css"
import "./css/rowcolumn.css"
import "./css/newHome.css"
import { Role } from "./components/role.js"
import aws_exports from "./aws-exports.js"

import banner2 from "./images/banner2.png"
import Header from "./components/Header"
import Loading from "./components/Loading"
import FooterwSocial from "./components/FooterwSocial"

import BackgroundImg from "./images/loginbg.jpg"
import logo from "./images/cci_logo2.png"

// importing react-redux
// import { Provider } from "react-redux";
// import { store } from "./redux/redux.js"
// import { useSelector, useDispatch } from "react-redux";

// IMPORTANT!
// This is called "code-splitting" and it improves page rendering as only the components
// (code) is rendered when needed. The <Loading/> component is shown as needed when rendering in process.
const Admin = lazy(() => import("./Admin"))
const PreQual = lazy(() => import("./PreQual"))
const Coach = lazy(() => import("./Coach"))
const Home = lazy(() => import("./Home2"))
const Locations = lazy(() => import("./Locations"))
const UnityRoom = lazy(() => import("./unity/unityRoom"))
const Report = lazy(() => import("./Report"))
const Analysis = lazy(() => import("./Analysis"))
const Scoreboard = lazy(() => import("./Scoreboard"))
// const Scoreboard = lazy(() => import("./Scoreboard"))
const Unassigned = lazy(() => import("./Unassigned"))
const Unknown = lazy(() => import("./Unknown"))

// Maintain browser history for app
const history = createBrowserHistory()

Amplify.configure(aws_exports)
API.configure(aws_exports)
Auth.configure(aws_exports)
toast.configure()

const App = () => {
  const [authState, setAuthState] = React.useState()
  const [user, setUser] = React.useState()
  const [role, setRole] = React.useState()
  const unityRooms = [
    "/hackerHideout",
    "/controlCenter",
    "/mainOffice",
    "/serverRoom",
    "/ralphsApartment",
    "/darlenesApartment",
    "/ryansApartment",
    "/mobileLab",
    "/practice",
    "/observatory"
  ] //update with available unity rooms

  const isSmallScreen = useMediaQuery({ query: "(max-width: 1155px" })

  // Handling AWS errors in a more user friendly way - toast you can always see
  // https://fkhadra.github.io/react-toastify/introduction
  const handleToastErrors: HubCallback = ({ payload }) => {
    if (payload.event === TOAST_AUTH_ERROR_EVENT && payload.message) {
      toast("ERROR: " + payload.message, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined
      })
    }
  }

  // Maintain current path location of user
  const pathname = window.location.pathname

  // Handling AWS errors in a more user friendly way - using Hub to listen for errors
  React.useEffect(() => {
    Hub.listen(UI_AUTH_CHANNEL, handleToastErrors)
    return () => Hub.remove(UI_AUTH_CHANNEL, handleToastErrors)
  })

  // When authentication state changes
  React.useEffect(() => {
    return onAuthUIStateChange((nextAuthState, authData) => {
      setAuthState(nextAuthState)
      setUser(authData)
      if (authData !== undefined && nextAuthState === AuthState.SignedIn) {
        setRole(authData.signInUserSession.accessToken.payload["cognito:groups"])
      }
    })
  }, [])

  const amplifyComponents = (
    /* https://docs.amplify.aws/ui/auth/authenticator/q/framework/react#props-slots-amplify-authenticator */
    <AmplifyAuthenticator
      hideToast
      usernameAlias="email"
      style={{ "--container-align": "flex-start", "--container-height": "auto" }}>
      <AmplifySignUp
        slot="sign-up"
        usernameAlias="email"
        headerText="Create Credentials Below"
        formFields={[
          {
            type: "email",
            label: "Email Address*",
            placeholder: "Enter the email provided during registration",
            required: true
          },
          {
            type: "password",
            label: "Password*",
            hint: "Requirements: length >= 8, uppercase letter, lowercase letter, number, special character",
            placeholder: "Enter your password of choice",
            required: true
          }
        ]}
      />
      <AmplifySignIn
        slot="sign-in"
        usernameAlias="email"
        headerText="Enter Credentials Below"
        formFields={[
          {
            type: "email",
            label: "Email Address*",
            required: true
          },
          {
            type: "password",
            label: "Password*",
            required: true
          }
        ]}
      />
      <AmplifyForgotPassword slot="forgot-password" usernameAlias="email" sendButtonText="Request Reset" />
      <AmplifySignOut />
    </AmplifyAuthenticator>
  )

  let requesterStatus;
  if ( authState === AuthState.SignedIn && user ) {
    // determine if authenticated user has been successfully assigned a role via AWS Cognito (AWS Lambda triggers)
    requesterStatus = role ? "fullAuth" : "errorAuth";
  } else if ( ["/scoreboard", "/practice"].includes(pathname) ) {
    // allow for the above url paths to be public
    requesterStatus = "public";
  } else {
    // direct users to login page if not authenticated
    requesterStatus = "preAuth";
  }

  switch (requesterStatus) {
    case "fullAuth":
      return (
        <div>
          <Router history={history}>
            <Suspense fallback={<Loading />}>
              {!unityRooms.includes(pathname) ? <Header role={role} /> : <span></span>}
    
              {/* Switch executes in the order of the Routes
                 therefore Unknown is last to catch any page that doesn't first match the others defined */}
              <Switch>
                <Route exact path="/" component={() => <Home role={role}/>} />
                <Route exact path="/scoreboard" component={Scoreboard} />
                {/* <Route exact path="/admin" component={role.includes(Role.Admin) ? Admin : Unknown} /> */}
                {/* <Route
                  exact
                  path="/prequal"
                  component={role.includes(Role.Admin) || role.includes(Role.Student) ? PreQual : Unknown}
                /> */}
                {/* <Route exact path="/coach" component={role.includes(Role.Coach) ? Coach : Unknown} /> */}
                {/* <Route
                  exact
                  path="/locations"
                  component={role.includes(Role.Admin) || role.includes(Role.Student) ? Locations : Unknown}
                /> */}
                {/* <Route
                  exact
                  path="/analysis"
                  component={role.includes(Role.Admin) || role.includes(Role.Student) ? Analysis : Unknown}
                /> */}
                <Route
                  exact
                  path="/hackerHideout"
                  component={
                    role.includes(Role.Admin) || role.includes(Role.Student)
                      ? () => <UnityRoom awsName={"hackerHideout"} sceneName={"Hacker Hideout"} public={false} />
                      : Unknown
                  }
                />
                <Route
                  exact
                  path="/controlCenter"
                  component={
                    role.includes(Role.Admin) || role.includes(Role.Student)
                      ? () => <UnityRoom awsName={"controlCenter"} sceneName={"Control Center"} public={false} />
                      : Unknown
                  }
                />
                <Route
                  exact
                  path="/mainOffice"
                  component={
                    role.includes(Role.Admin) || role.includes(Role.Student)
                      ? () => <UnityRoom awsName={"mainOffice"} sceneName={"Main Office"} public={false} />
                      : Unknown
                  }
                />
                <Route
                  exact
                  path="/mobileLab"
                  component={
                    role.includes(Role.Admin) || role.includes(Role.Student)
                      ? () => <UnityRoom awsName={"mobileLab"} sceneName={"Mobile Lab"} public={false} />
                      : Unknown
                  }
                />
                <Route
                  exact
                  path="/serverRoom"
                  component={
                    role.includes(Role.Admin) || role.includes(Role.Student)
                      ? () => <UnityRoom awsName={"serverRoom"} sceneName={"Server Room"} public={false} />
                      : Unknown
                  }
                />
                <Route
                  exact
                  path="/ralphsApartment"
                  component={
                    role.includes(Role.Admin) || role.includes(Role.Student)
                      ? () => <UnityRoom awsName={"ralphsApartment"} sceneName={"Ralph's Apartment"} public={false} />
                      : Unknown
                  }
                />
                <Route
                  exact
                  path="/darlenesApartment"
                  component={
                    role.includes(Role.Admin) || role.includes(Role.Student)
                      ? () => <UnityRoom awsName={"darlenesApartment"} sceneName={"Darlene's Apartment"} public={false} />
                      : Unknown
                  }
                />
                <Route
                  exact
                  path="/ryansApartment"
                  component={
                    role.includes(Role.Admin) || role.includes(Role.Student)
                      ? () => <UnityRoom awsName={"ryansApartment"} sceneName={"Ryan's Apartment"} public={false} />
                      : Unknown
                  }
                  />
                  <Route
                    exact
                    path="/observatory"
                    component={
                      role.includes(Role.Admin) || role.includes(Role.Student)
                        ? () => <UnityRoom awsName={"observatory"} sceneName={"Observatory"} public={false} />
                        : Unknown
                  }
                />
                <Route
                  exact
                  path="/practice"
                  component={() => <UnityRoom awsName={"practice"} sceneName={"Practice Room"} public={true} />}
                />
                <Route component={Unknown} />
              </Switch>
            </Suspense>
          </Router>
        </div>
      );
    case "errorAuth":
      return (
        <div>
          <Router history={history}>
            <Suspense fallback={<Loading />}>
              <Switch>
                <Route component={Unassigned} />
              </Switch>
            </Suspense>
          </Router>
        </div>
      );
    case "public":
      return (
        <div>
          <Router history={history}>
            <Suspense fallback={<Loading />}>
              {pathname === "/scoreboard" && (
                <section className="header-bottom-bar" style={{ paddingTop: "0", paddingBottom: "0"}}>
                    <a href="/"><img src={banner2} width="100%" alt="Welcome logo" /></a>
                </section>
              )}
    
              <Switch>
                {pathname === "/scoreboard" ? (
                  <Route exact path="/scoreboard" component={Scoreboard} />
                ) : (
                  <Route
                    exact
                    path="/practice"
                    component={() => <UnityRoom awsName={"practice"} sceneName={"Practice Room"} public={true} />}
                  />
                )}
                <Route component={Unknown} />
              </Switch>
            </Suspense>
          </Router>
        </div>
      );
    // includes the case 'preAuth', but all other options should lead to login page
    default:
      return (
        // Display authentication page when user is not signed in or looking at scoreboard
        <Box component="div" style={{ height: "100%", width: "100%", display: "flex", flexDirection: "column" }}>
          {/* <Provider store={store}> */}
          {/*https://fkhadra.github.io/react-toastify/introduction*/}
          <Toast />
          <Box
            component="div"
            style={
              isSmallScreen
                ? {
                    height: "100vh",
                    display: "flex",
                    flexDirection: "column",
                    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(${BackgroundImg})`,
                    backgroundSize: "cover",
                    borderTop: "15px solid #BA8C2D",
                    borderBottom: "15px solid #BA8C2D"
                  }
                : {
                    height: "100vh",
                    display: "flex",
                    flexDirection: "row",
                    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(${BackgroundImg})`,
                    backgroundSize: "cover",
                    borderTop: "15px solid #BA8C2D",
                    borderBottom: "15px solid #BA8C2D"
                  }
            }>
            {/* left side */}
            {isSmallScreen ? null : (
              <Box
                component="div"
                style={{
                  height: "auto",
                  width: "65%",
                  marginTop: "3rem",
                  marginBottom: "3rem"
                }}>
                <Grid container spacing={4}>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography className="sgctitle" variant="h3" style={{textAlign: "center", fontWeight: "bold", color: "white" }}>
                          Space Grand Challenge
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography className='titleCCIC2022' variant="h5" style={{ textAlign: "center", color: "white" }}>
                          
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} align="center">
                    <Box component="div" style={{ width: "90%" }}>
                      <Grid container spacing={0}>
                        <Grid item xs={12}>
                          <Box component="div" style={{ backgroundColor: "#BA8C2D" }}>
                            <Grid container>
                              <Grid item xs={6}>
                                <Typography
                                  className="loginSubTitle"
                                  variant="h4"
                                  style={{ textAlign: "center", fontWeight: "bold", color: "white" }}>
                                  Mission Kolluxium Z-85-0
                                </Typography>
                              </Grid>
                              <Grid item xs={6}>
                                <Typography className="loginSubTitle" variant="h4" style={{ textAlign: "center", fontWeight: "bold" }}>
                                  October 7-8, 2022
                                </Typography>
                              </Grid>
                            </Grid>
                          </Box>
                        </Grid>
                        <Grid item xs={12}>
                          <Box
                            className='bodyText'
                            component="p"
                            style={{
                              textAlign: "left",
                              color: "white",
                              fontSize: "1rem",
                              marginTop: "1",
                              marginBottom: "0"
                            }}>
                          Cal Poly's California Cybersecurity Institute (CCI) introduces its new global Space Grand Challenge (SGC) to inspire and empower the next generation of cyber professionals. Middle and high school students from across the globe will compete with their team to solve the fictional gamified satellite cybercrime and win the SGC! This year's mission is called Mission Kolluxium Z-85-0 and involves challenges with intricate, multi-layered cybercrime plots featuring complex characters, physical and digital evidence chains, and puzzles.
                          </Box>
                        </Grid>
                        <Grid item xs={12}>
                          <Box
                          className='bodyText'
                            component="p"
                            style={{
                              textAlign: "left",
                              color: "white",
                              fontSize: "1rem",
                              marginTop: "1",
                              marginBottom: "0"
                            }}>
                            Participate in a gamified satellite cybercrime challenge scenario crafted by Cal Poly's
                            California Cybersecurity Institute. This year, students will be competing in Mission Kolluxium
                            Z-85-0 to solve challenges that involve intricate, multi-layered cybercrime plots featuring
                            complex characters, physical and digital evidence chains, and puzzles.
                          </Box>
                        </Grid>
                        <Grid item xs={12}>
                          <Box
                          className='bodyText'
                            component="p"
                            style={{
                              textAlign: "left",
                              color: "white",
                              fontSize: "1rem",
                              marginTop: "1",
                              marginBottom: "0"
                            }}>
                            Join the challenge today!
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            )}
    
            {/* right side */}
            <Box
              component="div"
              style={{
                height: "auto",
                width: "auto",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginTop: "6rem",
                marginRight: "1rem",
                marginBottom: "3rem"
                // backgroundColor: "#BA8C2D",
                // borderRadius: "0.5rem"
              }}>
              <img
                src={logo}
                alt="CCI logo"
                style={{
                  position: "absolute",
                  top: "1rem",
                  zIndex: "2",
                  marginLeft: "9rem",
                  width: "13%",
                  minWidth: "10rem"
                }}
              />
              <Box
                component="div"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "#BA8C2D",
                  borderRadius: "0.5rem"
                }}>
                {amplifyComponents}
              </Box>
              {authState === AuthState.ConfirmSignUp ? (
                <Alert severity="info" style={{ margin: "1rem" }}>
                  A verification code has been sent to your email.
                </Alert>
              ) : null}
            </Box>
          </Box>
    
          {/* footer */}
          <Box component="div" style={{ width: "100%" }}>
            <footer className="home-footer">
              <FooterwSocial lightFooter={false} />
            </footer>
          </Box>
          {/* </Provider> */}
        </Box>
      );
  }
}

export default App;