const aws_exports = {
    Auth: {
        region: "us-east-1",
        userPoolId: "us-east-1_lvXNKy9kh",
        userPoolWebClientId: "16kf44aqo6us81cbbohsq7qe4j",
        identityPoolId: "us-east-1:6bf12577-40a8-43a9-bce1-15de699dddd2"
    },
    API: {
        endpoints: [
            { name: 'code', endpoint: "https://x7fmrsmva9.execute-api.us-east-1.amazonaws.com/DEV/" },
            { name: 'registration', endpoint: "https://z4izufus88.execute-api.us-east-1.amazonaws.com/DEV/" }
        ] 
    },
    Websocket: "wss://7fe0mxzi13.execute-api.us-east-1.amazonaws.com/DEV"
}; export default aws_exports;
