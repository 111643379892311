import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.min.css"

// Handling AWS errors in a more user friendly way - custom toast
// https://fkhadra.github.io/react-toastify/introduction

// Use the following to create a toast:
/* 
	 toast("this is a toast notification", {
    position: "bottom-right",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined
  })
*/

const Toast = () => {
	return (
		<ToastContainer
			toastClassName="notification-toast"
			style={{ width: "35%" }}
			limit={5}
			position="bottom-right"
			autoClose={5000}
			hideProgressBar
			newestOnTop={false}
			closeOnClick
			rtl={false}
			pauseOnFocusLoss
			draggable
			pauseOnHover
		/>
	)
}

export default Toast
